import "bootstrap";
import "jquery-mousewheel";
import "malihu-custom-scrollbar-plugin";
import Rails from "rails-ujs";
import AutoNumeric from 'autonumeric'
import * as ActiveStorage from "activestorage";

import 'select2'
import 'select2/dist/js/i18n/it'

import "./cocoon";
import 'flatpickr';
import MyApp from "./app";
import "./members/course_registrations";
import "./members/top_navbar";
import "bootstrap4-toggle";
import "moment";
import "moment/locale/it";
import "./bootstrap-datetimepicker"
import "cleave.js";

window.MyApp = MyApp;

Rails.start();
ActiveStorage.start();


// auto-show
$(document).on('change', 'input[data-auto-show], select[data-auto-show]', MyApp.onChangeAutoShow);

$(document).on('change', '[data-autosubmit=change]', function(event) {
  if ($(this).closest("form").find('[type="submit"]').length) {
    $(this).closest("form").find('[type="submit"]').trigger("click");
  } else {
    $(this).closest("form").submit();
  }
});

$(document).on('inserted.bs.popover', '.with-more-info[data-path]', function() {
  var e = $(this);
  $.get(e.data('path'));
});

$(document).on('dp.change', '[data-provide="datetimepicker"]', function(event) {
  var $this = $(this);
  var dtpicker = $this.data('DateTimePicker');
  if ( dtpicker ) {
    var dateObj = dtpicker.date();
    var dateVal = dateObj == null ? '' : dateObj.format('YYYY-MM-DD HH:mm:ss Z');
    var $ref = $($this.data('ref'));
    $ref.val(dateVal);
  }
});
$(document).on('dp.change', '[data-provide="datepicker"]', function(event) {
  var $this = $(this);
  var dtpicker = $this.data('DateTimePicker');
  if ( dtpicker ) {
    var dateObj = dtpicker.date();
    var dateVal = dateObj == null ? '' : dateObj.format('YYYY-MM-DD');
    var $ref = $($this.data('ref'));
    $ref.val(dateVal);
  }
});

//Get the value of Start and End of Week
$(document).on('dp.change', '.calendar-picker', function (e) {
  $(this).closest('form').find('button[type="submit"]').click();
});


$(function() {
  MyApp.initOnLoad();
  $("#sidebar > .sidebar-content").mCustomScrollbar({
    theme: "minimal-dark",
    scrollInertia: 250,
    mouseWheel: {
      preventDefault: true,
    }
  });

  $('#sidebarCollapse').on('click', function () {
    $('#sidebar, .main').toggleClass('collapsed');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });

  $('.sidebar-collapse').on('click', function() {
    $.ajax({
      method: 'POST',
      url: '/toggle_sidebar_mode',
      data: { collapsed: $('#sidebar').hasClass('collapsed') }
    })
  });

  $('.course_sessions').on('click', '.add-week-day', function(){
    var row = $('#new-week-record tr').first().clone();
    $(this).closest('.form-group').find('table').append(row);
  }).on('click', '.remove-week-day', function(){
    $(this).closest('tr').remove();
  });

  $("#show_sessions_offline").change(function(){
    $(".ajax-load").removeClass('d-none');
    $.getScript($(this).data('url') + "?show_sessions_offline=" + $(this).prop('checked'));
  });

  $(document).on('cocoon:after-insert', function(event, insertedItem) {
    var $item = $(insertedItem)
    $item.find('[data-provide="flatpickr"]').each(function () {
      MyApp.initFlatPickr($(this))
    });
  });
  $(document).on('change', '#show_canceled', function(){
    var href = window.location.href;
    var sym = href.indexOf('?') > 0 ? '&' : '?';
    var url = href  + sym + 'show_canceled=' + $(this).prop('checked');
    $(".ajax-load").removeClass('d-none');
    $.getScript(url);
  });

  $(document).on('click', '.paid-confirmed-wrapper', function(){
    var check_box = $(this).find('input');
    var checked = check_box.prop('checked');
    if(checked){
      if(confirm('Impostando il pagamento della rata come non confermato la ricevuta verrà eliminata. Precedere?')){
        $(".ajax-load").removeClass('d-none');
        $.post(check_box.data('url'), {confirmed: false});
      }
    }else{
      if(confirm('Impostando il pagamento della rata come confermato verrà generata la ricevuta. Precedere?')){
        $(".ajax-load").removeClass('d-none');
        $.post(check_box.data('url'), {confirmed: true})
      }
    }
  });

  // dopo le chiamate ajax
  $(document).on('ajax:beforeSend', function(){
    $(".ajax-load").removeClass('d-none');
  });

  $(document).on('ajax:complete', function(xhr, status){
    $(".ajax-load").addClass('d-none');
    MyApp.initOnLoad();
  });


  $(document).on('change', '.js-profile-course-season', function() {
    $.ajax({
      url: $(this).data('url'),
      type: 'GET',
      data: {
        course_season_id: $(this).val()
      }
    });
  });

  $(document).on('click', '.js-profile-payment-price-list-item', function() {
    $('.profile-payment-form .payment-entrances-number').val($(this).data('entrances-number'));
    AutoNumeric.getAutoNumericElement(document.getElementById('profile_payment_object_amount')).set($(this).data('amount'));
  });


});

